@import "../../assets/styles/shared.scss";
/**
 * Footer ==================================================
*/
.page-footer {
	box-sizing: border-box;
	width: 100%;
	padding: 32px 0 0 0;
	background-color: $primaryDarkest;
	color: $white;
	.follow {
		color: $white;
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		.icon {
			margin-top: 16px;
			transform: scale(1);
			transition: transform .4s cubic-bezier(.05,.8,.5,1);
		}
		&:hover,
		&:focus {
			.icon {
				transform: scale(1.2);
			}
		}
	}

	.footer-links {
		margin-top: 20px;
	}
	.nav-item {
		.nav-link {
			position: relative;
			padding: .5rem;
			color: $white;
			font-size: 14px;
			font-weight: 300;
			&:after {
				content: "";
				position: absolute;
				right: 0;
				top: 9px;
				display: block;
				height: 14px;
				width: 1px;
				background-color: $white;
			}
		}
		&:last-child {
			& .nav-link:after {
				content: none;
			}
		}
	}
}

.ninu {
	padding: 10px 0;
	h6 {
		font-size: 14px;
		margin-bottom: 0;
		opacity: 0.5;
	}
}