/**
 * Sections ==================================================
*/
.section {
	position: relative;
	width: 100%;
	&.section--image {
		height: 100vh;
		.bigImage {
			position: relative;
			height: 100%;
			margin: 0;
			.bigImage__image {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center center;
			}
			.bigImage__source {
				position: absolute;
				right: 10px;
				bottom: 10px;
				color: $white;
				text-align: right;
				font-size: 14px;
				font-weight: 300;
				opacity: 0.6;
				a {
						color: $white;
				}
			}
		}
		.blockquote-container {
			position: absolute;
			left: 20px;
			bottom: 70px;
			color: $white;
			width: 660px;
			max-width: calc(100vw - 40px);
			.blockquote-footer {
				color: $white;
			}

			@include media-breakpoint-up(lg) {
				left: 60px;
				bottom: 170px;
			}
		}
	}

	&.section--text {
			display: flex;
			justify-content: center;
			padding: 64px 0;
			@include media-breakpoint-up(lg) {
				padding: 128px 0;
			}
			.content {
					width: 960px;
					max-width: 90%;
			}
	}

	&.section--imprint,
	&.section--privacy {
		padding: 128px 0;
		@include media-breakpoint-up(md) {
			padding: 160px 0;
		}
		@include media-breakpoint-up(lg) {
			padding: 240px 0;
		}
		@include media-breakpoint-up(xl) {
			padding: 128px 0;
		}
	}
}