@import-normalize; /* bring in normalize.css styles */
@import "./assets/styles/shared.scss";

$body-color:                $black;
$navbar-dark-color:                 rgba($white, .85);
$navbar-dark-hover-color:           rgba($white, .75);
$navbar-dark-active-color:          $primary;

$blockquote-footer-color:     fade($white,.8);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';



@import "./assets/styles/header.scss";
@import "./assets/styles/footer.scss";
@import "./assets/styles/quote.scss";
@import "./assets/styles/pages.scss";


/**
 * wRapper ==================================================
*/
.eminem {
	display: flex;
	flex-flow: row nowrap;
	flex-direction: column;
	min-height: 100vh;
	font-family: demos-next, sans-serif;

	font-size: 18px;
	line-height: 1.4;

	@include media-breakpoint-up(lg) {
		font-size: 24px;
	}	
}

main {
	box-sizing: border-box;
	flex: 1 0 auto;
}

.intro {
	position: relative;
	.downlink {
		box-sizing: border-box;
		display: block;
		position: absolute;
		left: 50%;
		bottom: 30px;
		transform: translateX(-50%);
		padding-bottom: 20px;
		cursor: pointer;
		.downlink__icon {
			transform: translateY(0);
			transition: all .4s cubic-bezier(.05,.8,.5,1);
		}
		&:hover {
			.downlink__icon {
				transform: translateY(10px);
			}
		}
	}
}

.anchor {
	position: relative;
	display: inline-block;
	text-decoration: none;
	color: $black;
	&:after {
			content: "";
			display: block;
			position: absolute;
			z-index: -1;
			left: 0;
			bottom: -2px;
			width: 100%;
			height: 3px;
			background-color: $primaryLight;
			transition: all .4s cubic-bezier(.05,.8,.5,1);
			@include media-breakpoint-up(lg) {
				bottom: -1px;
			}
	}

	&:hover,
	&:focus {
		color: $black;
			&:after {
					height: 26px;
					@include media-breakpoint-up(lg) {
						height: 31px;
					}
			}
	}
}

.bg-primary-dark {
	background-color: $primaryDarkest !important;
}

.feedContainer {
	padding-bottom: 100%;
	height: 0;
}
#instafeed {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 12px;
	grid-row-gap: 12px;
	@include media-breakpoint-up(lg) {
		grid-column-gap: 24px;
		grid-row-gap: 24px;
	}
	a {
		position: relative;
		overflow: hidden;
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: all .4s cubic-bezier(.05,.8,.5,1);
		}
		&:hover {
			img {
				transform: scale(1.1);
			}
		}
	}
}