@import "../../assets/styles/shared.scss";
/**
 * Header ==================================================
*/
// .page-header {
// 	position: fixed;
// 	z-index: 999999999999999999999;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	background-color: $primaryDarkest;
// 	color: $white;
// }



@include media-breakpoint-up(lg) {
	.navbar {
		padding: 0 1rem;
	}
}

.navbar-brand {
	position: absolute;
	top: -30px;
	left: 15px;

	width: 80px;

	transition: all .4s cubic-bezier(.05,.8,.5,1);
	animation-name: logoAnimation;
	animation-duration: 750ms;
	animation-iteration-count: 1;
	animation-timing-function: ease-out;
	&:hover {
			top: -10px;
	}
	@include media-breakpoint-up(md) {
		width: 100px;
	}
	@include media-breakpoint-up(lg) {
		width: 140px;
	}
}

.nav-link {
	padding-top: 1rem;
	padding-bottom: 1rem;
	cursor: pointer;
	&.active {
		background-color: rgba($white, .9);
	}
}

@keyframes logoAnimation {
	0%   {
			top: -315px;
	}
	70% {
			top: -10px;
	}
	100% {
			top: -30px;
	}
}