.blockquote-container {
	position: relative;
}

.blockquote {
	box-sizing: border-box;
	margin-bottom: 16px;
	padding-left: 14px;
	padding-top: 28px;

	background: url(../images/quotes.svg) no-repeat top left;
	background-size: 94px 63px;

	font-size: 24px;
  line-height: 1.4;


	@include media-breakpoint-up(lg) {
		padding-left: 20px;
		padding-top: 40px;
		background-size: 130px 87px;
		font-size: 32px;
	}
}
.blockquote-footer {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		width: 100%;
}